import axios from "axios";
import router from "../router";
import * as util from "../util";
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

let Options = {
  type: "error",
  //showClose: true,
  center: true
};

// 创建axios配置
let instance = axios.create({
  baseURL: "/api/",
  timeout: 30000,
  headers: { "Content-Type": "application/json; charset=utf-8" }
});

// request 拦截器
instance.interceptors.request.use(
  (config) => {
    if (!(config.timeout > 30000 || config.silence)) NProgress.start();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response 拦截器
instance.interceptors.response.use(
  (response) => {
    NProgress.done();
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
  },
  (error) => {
    NProgress.done();
    if (error.response && error.response.status) {
      console.warn("http response:", error.config ? error.config.url : null, error.response);
      switch (error.response.status) {
        case 400:
          Message({
            message: "错误请求",
            type: "error",
            //showClose: true,
            center: true
          });
          break;
        case 401:
          if (!window.location.pathname.toLowerCase().startsWith("/login")) {
            util.removeCookie("access-token");
            let fullPath = window.location.pathname + window.location.search;
            if (!(fullPath.length > 1)) fullPath = "";
            router.replace({
              path: "/login?redirect=" + fullPath
            });
            return Promise.reject(null);
          }
          break;
        case 403:
          util.removeCookie("access-token");
          Message({
            message: "拒绝访问",
            type: "error"
            //showClose: true
          });
          router.replace({
            path: "/login"
          });
          break;
        case 404:
          Options.message = "请求错误，未找到该资源";
          Message(Options);
          break;
        case 405:
          Message({
            message: "请求方法未允许",
            type: "error"
            //showClose: true
          });
          break;
        case 408:
          Message({
            message: "请求超时",
            type: "error"
            //showClose: true
          });
          break;
        case 500:
          Message({
            message: "请求服务出错",
            type: "error"
            //showClose: true
          });
          break;
        case 501:
          Message({
            message: "网络未实现",
            type: "error"
            //showClose: true
          });
          break;
        case 502:
          Message({
            message: "服务不可用",
            type: "error"
            //showClose: true
          });
          break;
        case 503:
          Message({
            message: "服务不可用",
            type: "error"
            //showClose: true
          });
          break;
        case 504:
          Message({
            message: "服务不可用",
            type: "error"
            //showClose: true
          });
          break;
        case 505:
          Message({
            message: "http版本不支持该请求",
            type: "error"
            //showClose: true
          });
          break;
        default:
          Message({
            message: error.response.status + "请求错误",
            type: "error"
          });
          break;
      }
      return Promise.reject(error.response.status + " " + error.response.statusText);
    } else {
      if (error.code === "ECONNABORTED") {
        console.warn("http timeout:", error.config ? error.config.url : "", error);
        //请求被终止，一般为超时
        Message({
          message: "服务接口请求超时",
          type: "error"
        });
      } else {
        //可能被取消
        console.debug("http error:", error.config ? error.config.url : "", error);
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
